import { useDashboardStore } from '@/store/dashboard';
import { FarmType } from './../types/farm';
import { BarnType } from './../types/barn';
import { SensorType } from './../types/sensor';
import { BarnVisibilityType } from './../types/barn_visibility';
import { data_cam_time_limit } from "@/utils/data_time_limit";
import { trendlineType } from '@/types/trendline';
import { PolynomialRegression } from 'ml-regression-polynomial';

const store = useDashboardStore();


export function compareDateToCurrent(date: string): number {
    return Math.ceil(Math.abs((new Date().getTime() - new Date(date).getTime()) / (1000 * 60)))
}

export function isSensorOnline(datetime_last_ping: string, verification_period: number): boolean {
    return compareDateToCurrent(datetime_last_ping) < verification_period
}

export function isSensorNotAnswering(datetime_last_ping: string, verification_period: number): boolean {
    return verification_period <= compareDateToCurrent(datetime_last_ping) 
    && compareDateToCurrent(datetime_last_ping) <= verification_period*2
}

export function isSensorOffline(datetime_last_ping: string, verification_period: number): boolean {
    return compareDateToCurrent(datetime_last_ping) > verification_period*2
}

export function getSensorsInFarm(farm: FarmType, visibility: BarnVisibilityType): SensorType[] {
    const sensors: SensorType[] = [];
    farm.barns.forEach((barn: BarnType) => {
        const isActive = visibility !== 'hide_disabled' || barn.is_last_batch_active;
        if (isActive) {
            const barnSensors = barn.sensors.map((sensor) => ({
                ...sensor,
                barn_name: barn.name,
            }));
            sensors.push(...barnSensors);
        }
    });
    return sensors;
}

export function getSmartcamsOnline(smartcams: SensorType[]): SensorType[] {
    return smartcams.filter((smartcam: SensorType) => isSmartcamOnline(smartcam))
}

export function isSmartcamOnline(smartcam: SensorType): boolean {
    const barn = store.getBarnByPK(smartcam.barn)
    if (barn != undefined) {
      const age = compareDateToCurrent(barn.last_batch_accommodation_date)
    // 32 days have 46080 minutes
      if (age < 46080) {
        return compareDateToCurrent(smartcam.last_acquisition_date) < data_cam_time_limit
      }
    }
    if (smartcam.datetime_last_data == null) return false
    return compareDateToCurrent(smartcam.datetime_last_data) < data_cam_time_limit
}

export function generateTrendline(weights: number[], dates: Date[]): trendlineType[] {
    // Convert dates to timestamps for easier calculations
    const timestamps: number[] = dates.map(date => date.getTime());

    // Calculate the polynomial coefficients
    const predictWeight = new PolynomialRegression(timestamps, weights, 3);

    // Calculate trendline for 10 days after the last date
    const lastDate = Math.max(...timestamps);
    const futureTrendline: { date: Date, weight: number }[] = [];
    for (let i = 1; i <= 10; i++) {
        const futureDate = lastDate + (i * 24 * 60 * 60 * 1000); // 1 day in milliseconds
        const futureWeight = predictWeight.predict(futureDate);
        futureTrendline.push({
        date: new Date(futureDate),
        weight: futureWeight
        });
    }

    return futureTrendline;
    }



